import { useHistory } from "react-router-dom";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { SHIPDA_ADMIN_FILTER_ATOMS } from "states/filters";

import { ADMIN_FORWARDING_MANAGER_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminAuth";
import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/BUSINESS_MANAGEMENT_QUERY";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { BusinessManagementType } from "@sellernote/_shared/src/types/forwarding/businessManagement";
import { THIS_YEAR_AS_TWO_DIGITS } from "@sellernote/_shared/src/utils/common/date";
import { RadioGroupFilterOptionList } from "@sellernote/_shared-for-forwarding-admin/src/hooks/useRadioGroupFilter";
import useSelectFilter from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSelectFilter";

import { numberFormat } from "services/util";

import Loading from "components/Loading";

import BusinessManagementTable from "./BusinessManagementTable";

export const radioGroupOptionList: RadioGroupFilterOptionList<BusinessManagementType>[] =
  [
    {
      label: "DB 영업관리",
      value: "DBManagement",
    },
    {
      label: "견적제출 요망",
      value: "beforeEstimate",
    },
    {
      label: "사후관리",
      value: "waiting",
    },
    {
      label: "재영업 목록",
      value: "remarketing",
    },
  ];

const BusinessManagementInfo = ({
  adminUserId,
}: {
  adminData: ForwardingAdminUserListItem[];
  adminUserId: number;
}) => {
  const history = useHistory();

  const [businessManagementFilter, setBusinessManagementFilter] =
    useRecoilState(
      SHIPDA_ADMIN_FILTER_ATOMS.ADMIN_BUSINESS_MANAGEMENT_FILTER_LIST
    );

  const handleForwardingManagerFilterHistoryChange = (value: number) => {
    setBusinessManagementFilter({
      ...businessManagementFilter,
      adminId: value,
    });
  };

  const {
    SelectFilterPanel: ForwardingManagerSelectFilterPanel,
    selectFilter: forwardingManagerSelectFilter,
  } = useSelectFilter({
    defaultValue: businessManagementFilter?.adminId ?? adminUserId,
    selectFilterOptionList: [
      { label: "전체", value: 0 },
      ...ADMIN_FORWARDING_MANAGER_OPTION_LIST,
    ],
    label: "담당자",
    setHistoryData: handleForwardingManagerFilterHistoryChange,
  });

  const { data: businessManagementInfo } =
    BUSINESS_MANAGEMENT_QUERY.useGetBusinessManagementInfo({
      id: forwardingManagerSelectFilter?.value ?? adminUserId,
    });

  if (!businessManagementInfo) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          p: 1,
          background: "#fff",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>담당자명:</Typography>
          {ForwardingManagerSelectFilterPanel}
        </Box>

        <Divider orientation="vertical" flexItem />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography>화주사(배정):</Typography>

            <Button
              onClick={() => {
                history.push(`/users/list`, {
                  adminId: forwardingManagerSelectFilter?.value ?? adminUserId,
                  userType: "consignee",
                });
              }}
              sx={{ minWidth: "auto", p: "4px" }}
              variant="text"
            >
              {businessManagementInfo.customerCnt}
            </Button>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography>진행중:</Typography>

            <Button
              onClick={() => {
                history.push(`/bid`, {
                  adminId: forwardingManagerSelectFilter?.value ?? adminUserId,
                  status: "inProgress",
                });
              }}
              sx={{ minWidth: "auto", p: "4px" }}
              variant="text"
            >
              {businessManagementInfo.proceedCnt}
            </Button>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography>진행건수:</Typography>

            <Typography>{businessManagementInfo.proceedBidCnt}</Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography>{THIS_YEAR_AS_TWO_DIGITS}년도 누적 수주액:</Typography>

            <Typography>
              {numberFormat(Math.floor(businessManagementInfo.yearSalesAmt))}원
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography>당월 수주액:</Typography>

            <Typography>
              {numberFormat(Math.floor(businessManagementInfo.monthSalesAmt))}원
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <BusinessManagementTable
        adminId={forwardingManagerSelectFilter?.value ?? adminUserId}
      />
    </>
  );
};

export default BusinessManagementInfo;
