import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";

import Content from "./Content";
import Header from "./Header";

export default function PartnerManagement() {
  return (
    <Layout>
      <Header />

      <Content />
    </Layout>
  );
}
