import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";

import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import FCL_HTML from "./FCL.html";
import LCL_HTML from "./LCL.html";
import useEmailBrochures from "./useEmailBrochures";

function EmailBrochures() {
  const {
    showPreview: showPreviewFCL,
    Preview: PreviewFCL,
    copyCode: copyCodeFCL,
  } = useEmailBrochures("FCL 브로셔", FCL_HTML);

  const {
    showPreview: showPreviewLCL,
    Preview: PreviewLCL,
    copyCode: copyCodeLCL,
  } = useEmailBrochures("LCL 브로셔", LCL_HTML);

  return (
    <>
      <Layout
        breadcrumbs={["영업 관리"]}
        title={"이메일 브로셔"}
        right={
          <Button
            type="ghost"
            icon={<InfoCircleOutlined />}
            onClick={() => {
              window.open(
                "https://www.notion.so/shipda/067d6c28f4474450a48e63794da173ff",
                "_blank"
              );
            }}
          >
            사용법
          </Button>
        }
      >
        <Row gutter={10} style={{ margin: 20 }}>
          <Col className="gutter-row" span={4}>
            <Card title="FCL">
              <Button type="primary" onClick={showPreviewFCL}>
                미리보기
              </Button>
              &nbsp;&nbsp;
              <Button type="default" onClick={copyCodeFCL}>
                코드 복사
              </Button>
              {PreviewFCL}
            </Card>
          </Col>

          <Col className="gutter-row" span={4}>
            <Card title="LCL">
              <Button type="primary" onClick={showPreviewLCL}>
                미리보기
              </Button>
              &nbsp;&nbsp;
              <Button type="default" onClick={copyCodeLCL}>
                코드 복사
              </Button>
              {PreviewLCL}
            </Card>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default withRequireAuth(EmailBrochures);
