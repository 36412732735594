import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { UPDATE_USER_DETAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidUser";
import ADMIN_BID_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_USER_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import useTeamAndUserDetail from "@sellernote/_shared-for-forwarding-admin/src/hooks/useTeamAndUserDetail";

function UserDetail() {
  const { handleSnackbarOpen } = useSnackbar();

  const { id }: { id: string } = useParams();

  const { control, watch, reset, formState } =
    useForm<UPDATE_USER_DETAIL_REQ>();

  const [isEditMode, setIsEditMode] = useState(false);

  const { data: userDetail, refetch: refetchUserDetail } =
    ADMIN_BID_USER_QUERY.useGetAdminNewBidUserDetail(Number(id));

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { data: adminList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const { data: fullfillmentManagerData } =
    ADMIN_BID_USER_QUERY.useGetFullfillmentManagers({});

  const {
    mutate: updateTeamDetail,
    ResponseHandler: ResponseHandlerOfUpdateTeamDetail,
  } = ADMIN_BID_USER_QUERY.useUpdateUserDetail({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        refetchUserDetail();
        reset();
        setIsEditMode(false);
      },

      customizeMessage: () => ({
        title: "유저 상세를 수정했습니다.",
      }),
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "유저 상세 수정에서 오류가 발생했습니다.",
      }),
    },
  });

  const handleTeamDetailUpdate = () => {
    if (
      Boolean(userDetail?.attachmentBRN?.length) &&
      watch().BRN?.length !== 10
    ) {
      handleSnackbarOpen("사업자 등록번호 10자리를 입력해주세요.", "warning");
      return;
    }

    updateTeamDetail({
      ...watch(),
      email: watch("email") !== userDetail?.email ? watch("email") : undefined,
      id: Number(id),
    });
  };

  const handleTeamDetailUpdateCancel = () => {
    reset();
    setIsEditMode(false);
  };

  const handleEditModeChange = () => {
    setIsEditMode(true);
  };

  const { Detail: UserDetail } = useTeamAndUserDetail({
    detailData: userDetail,
    portList,
    adminList,
    id,
    ResponseHandlerOfUpdateTeamDetail,
    handleTeamDetailUpdate,
    control,
    handleTeamDetailUpdateCancel,
    handleEditModeChange,
    isEditMode,
    type: "user",
    fullfillmentManagerData,
    refetchDetailData: refetchUserDetail,
  });

  return UserDetail;
}

export default withRequireAuth(UserDetail);
