import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { useAtom } from "jotai";

import ADMIN_BID_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_USER_QUERY";
import { UserListItem } from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import LegacyTable, {
  LegacyTableBodyRow,
} from "@sellernote/_shared-for-forwarding-admin/src/components/LegacyTable";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useGetForwardingManagerName from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetForwardingManagerName";
import useSearchWithDate from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithDate";
import useSearchWithTerm from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";
import { FORWARDING_ADMIN_USER_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/user/index";

import useHandleUserTable from "./hooks/useHandleUserTable";

import { TERM_SEARCH_TYPE_OPTION_LIST } from "./constants";
import ForwardingManagerFilter from "./ForwardingManagerFilter";
import UserCreateModalBody from "./UserCreateModalBody";

function UserList() {
  const [showsUserCreateModal, setShowsUserCreateModal] = useState(false);

  const handleUserCreateModalOpen = () => setShowsUserCreateModal(true);
  const handleUserCreateModalClose = () => setShowsUserCreateModal(false);

  const [userTableFilterData, setUserTableFilterData] = useAtom(
    FORWARDING_ADMIN_USER_ATOMS.USER_TABLE_FILTER_DATA
  );

  const history = useHistory();

  const resetCurrentPage = useCallback(() => {
    // 페이지가 0일 아닐때만 초기화 해준다.
    if (userTableFilterData.page !== 0) {
      setUserTableFilterData({
        ...userTableFilterData,
        page: 0,
      });
    }
  }, [setUserTableFilterData, userTableFilterData]);

  const historyTermSearchType = useMemo(() => {
    const targetSearchTypeOption = TERM_SEARCH_TYPE_OPTION_LIST.find(
      (option) => {
        return option.value === userTableFilterData.searchType;
      }
    );

    return targetSearchTypeOption;
  }, [userTableFilterData]);

  const { debouncedSearchTerm, termSearchType, TermSearchPanel, searchTerm } =
    useSearchWithTerm({
      termSearchTypeOptions: TERM_SEARCH_TYPE_OPTION_LIST,
      historyTermSearchType,
      historySearchTerm: userTableFilterData.searchTerm,
      resetCurrentPage,
    });

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions: TERM_SEARCH_TYPE_OPTION_LIST,
      historyStartDate: userTableFilterData.startDate?.toString(),
      historyEndDate: userTableFilterData.endDate?.toString(),
      resetCurrentPage,
    });

  const { sort, order, headCellList } = useHandleUserTable();

  const { data: userList } = ADMIN_BID_USER_QUERY.useGetAdminNewBidUserList({
    params: {
      page: userTableFilterData.page,
      perPage: userTableFilterData.perPage,
      searchTerm: debouncedSearchTerm,
      searchType: termSearchType?.value,
      ...(dateSearchType && startDate && endDate
        ? {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          }
        : {}),
      sort,
      order,
      ...(userTableFilterData.forwardingManagerId
        ? { forwardingManagerId: userTableFilterData.forwardingManagerId }
        : {}),
    },
    // 검색어 입력으로 페이지가 초기화되면서 중복요청이 되는걸 방지하기 위해 추가
    enabled: debouncedSearchTerm === searchTerm,
    onSuccess: () => {
      // 요청한 값을 jotai 전역변수에 저장한다.
      setUserTableFilterData({
        ...userTableFilterData,
        searchTerm: debouncedSearchTerm,
        searchType: termSearchType?.value,
        ...(dateSearchType && startDate && endDate
          ? {
              startDate: new Date(startDate),
              endDate: new Date(endDate),
            }
          : {}),
        sort,
        order,
      });
    },
  });

  const { getForwardingManagerName } = useGetForwardingManagerName();

  const rows = useMemo(() => {
    if (!userList?.list) return [];

    return userList?.list.map((v) => {
      const row: LegacyTableBodyRow<keyof UserListItem> = {
        id: v.id,
        company: v.company,
        country: v.country,
        name: v.name,
        phone: v.phone,
        email: v.email,
        forwardingManagerId: getForwardingManagerName(v.forwardingManagerId),
        searchCount: v.searchCount,
        totalBidsCount: v.totalBidsCount,
        inProgressBidCount: v.inProgressBidCount,
        createdAt: toFormattedDate(v.createdAt),
        handleRowClick: () => history.push(`/users/list/${v.id}`),
      };

      return row;
    });
  }, [getForwardingManagerName, history, userList?.list]);

  return (
    <Layout title={"사용자 관리"}>
      <Box sx={{ display: "flex", gap: 1, pt: 1, px: 1, background: "#fff" }}>
        {TermSearchPanel}
        {DateSearchPanel}

        <ForwardingManagerFilter />

        <Button
          variant="contained"
          onClick={handleUserCreateModalOpen}
          sx={{ marginLeft: "auto" }}
        >
          사용자 생성
        </Button>
      </Box>

      <Box sx={{ p: 1, background: "#fff" }}>
        <LegacyTable
          headCells={headCellList}
          rows={rows}
          toolbarItems={{
            left: [
              <Typography key="total">총 {userList?.total || 0}건</Typography>,
            ],
          }}
          pagination={{
            rowsPerPageOptions: [10, 25, 50, 100, 500, 1000, 10000],
            totalCount: userList?.total || 0,
            perPage: userTableFilterData.perPage,
            setPerPage: (perPage: number) => {
              setUserTableFilterData({
                ...userTableFilterData,
                perPage: perPage,
              });
            },
            currentPage: userTableFilterData.page,
            setCurrentPage: (currentPage: number) => {
              setUserTableFilterData({
                ...userTableFilterData,
                page: currentPage,
              });
            },
          }}
        />
      </Box>

      <Modal
        sx={{ ".MuiBox-root": { padding: 1 } }}
        isOpened={showsUserCreateModal}
        handleClose={handleUserCreateModalClose}
        modalBody={
          <UserCreateModalBody
            onUserCreateModalClose={handleUserCreateModalClose}
          />
        }
      />
    </Layout>
  );
}

export default withRequireAuth(UserList);
