import { useAtomValue } from "jotai";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import { FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/trello";
import GeneralAndConsolidation from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/trello/GeneralAndConsolidation";

function GeneralTrelloList() {
  const fetchTrelloListParams = useAtomValue(
    FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS.FETCH_TRELLO_LIST_PARAMS
  );

  const { region, corpSizeType, blKind, ...trelloParams } =
    fetchTrelloListParams;

  const { data: trelloBidList } = TRELLO_BID_QUERY.useGetTrelloBidList({
    ...trelloParams,
    /** fetchTrelloListParams.region 값이 all 일때는, 국가 파라미터 전달을 하지 않아 KR, SG의뢰를 모두 불러옴 */
    ...(region === "all" ? [] : { region }),
    ...(corpSizeType === "all" ? [] : { corpSizeType }),
    ...(blKind === "all" ? [] : { blKind }),
    status: "inProgress",
    perPage: 300,
    serviceType: undefined,
  });

  const { data: trelloBidFinishedList } =
    TRELLO_BID_QUERY.useGetTrelloBidFinishedList({
      ...trelloParams,
      /** fetchTrelloListParams.region 값이 all 일때는, 국가 파라미터 전달을 하지 않아 KR, SG의뢰를 모두 불러옴 */
      ...(region === "all" ? [] : { region }),
      ...(corpSizeType === "all" ? [] : { corpSizeType }),
      ...(blKind === "all" ? [] : { blKind }),
      status: "finished",
      projectStatus: "finished",
      perPage: 200,
      serviceType: undefined,
    });

  const { adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  if (!trelloBidFinishedList || !trelloBidList || !adminUserList) {
    return (
      <Layout>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout>
      <GeneralAndConsolidation
        trelloListData={trelloBidList.concat(trelloBidFinishedList)}
        adminData={adminUserList}
      />
    </Layout>
  );
}

export default withRequireAuth(GeneralTrelloList);
