import { useMemo, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { Card, Col, Input, Row, Table } from "antd";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import { Country } from "@sellernote/_shared/src/types/common/common";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

const CountryList = () => {
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("nameKR");

  const { data: countryList } = ADMIN_COMMON_QUERY.useGetCountryList();

  const handleType = (value: any) => {
    setSearchType(value);
  };

  const handleSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const countryListFilterData = useMemo(() => {
    if (!countryList) {
      return [];
    }
    const filterData = countryList.filter((data: Country) => {
      switch (searchType) {
        case "nameKR":
          return data.name.includes(searchText);
        default:
          return data.name.includes("");
      }
    });
    return filterData;
  }, [searchText, searchType, countryList]);

  const columns = [
    {
      title: "번호",
      dataIndex: "id",
    },
    {
      title: "대륙",
      dataIndex: "continentNameKR",
    },
    {
      title: "국가코드",
      dataIndex: "name",
    },
    {
      title: "국가",
      dataIndex: "nameKR",
    },
    {
      title: "순서",
      dataIndex: "order",
    },
  ];
  return (
    <Layout breadcrumbs={["국가 관리"]} title={"국가 정보"}>
      <Card>
        <Row justify="end" align="middle" style={{ marginBottom: "10px" }}>
          <Row
            justify="space-between"
            align="middle"
            style={{ minWidth: "50%" }}
          >
            <Col span={24}>
              <Select
                defaultValue="nameKR"
                size="small"
                variant="outlined"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                <MenuItem value="nameKR">국가</MenuItem>
              </Select>

              <Input
                size="large"
                style={{ width: "calc(100% - 110px)", marginLeft: "10px" }}
                onChange={handleSearch}
                allowClear
              />
            </Col>
          </Row>
        </Row>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "10px" }}
        >
          <span>총 {countryListFilterData.length}개</span>
        </Row>

        <Table
          rowKey={(record: any) => record.id}
          dataSource={countryListFilterData}
          columns={columns}
          pagination={{
            total: countryListFilterData.length,
            pageSize: 25,
            showSizeChanger: false,
          }}
        />
      </Card>
    </Layout>
  );
};

export default withRequireAuth(CountryList);
