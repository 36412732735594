import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import BackupIcon from "@mui/icons-material/Backup";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import ADMIN_PROMOTION_QUERY, {
  ADMIN_PROMOTION_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_PROMOTION_QUERY";
import {
  AdminPromotionDetailStatus,
  AdminPromotionStatus,
  AdminPromotionTableList,
} from "@sellernote/_shared/src/types/forwarding/adminPromotion";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/Table";

import usePromotionTableFilter, {
  radioGroupOptionList,
} from "pages/promotion/usePromotionTableFilter";

import PromotionFileUpload from "../PromotionFileUploadModal";
import ChangeBidInputModal from "./ChangeBidInputModal";
import PromotionDetailStatusConfirmModal from "./PromotionDetailStatusConfirmModal";

type CellId = keyof AdminPromotionTableList | "forwardingManager" | "file";

const PromotionTable = ({
  adminData,
  adminUserId,
}: {
  adminData: ForwardingAdminUserListItem[];
  adminUserId: number;
}) => {
  const history = useHistory();

  const queryClient = useQueryClient();

  const [showsUploadModal, setShowsUploadModal] = useState(false);
  const [showsChangeBidInputModal, setShowsChangeBidInputModal] =
    useState(false);
  const [listId, setListId] = useState(0);
  const [isPromotion, setIsPromotion] = useState(true);
  const [bidId, setBidId] = useState<number | null>(0);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [
    showsPromotionDetailStatusConfirmModal,
    setShowsPromotionDetailStatusConfirmModal,
  ] = useState(false);
  const [selectedStatus, setSelectedStatus] =
    useState<AdminPromotionStatus>("pending");
  const [promotionDetailId, setPromotionDetailId] = useState(0);

  const {
    radioGroupFilter,
    ForwardingManagerSelectFilterPanel,
    RadioGroupFilterPanel,
    PromotionTableFilter,
    dateSearchType,
    startDate,
    endDate,
    debouncedSearchTermWithObject,
    forwardingManagerSelectFilterValue,
  } = usePromotionTableFilter({
    adminUserId,
  });

  const { data: promotionList } = ADMIN_PROMOTION_QUERY.useGetPromotionList(
    {
      page: currentPage,
      perPage,
      promotionDetailStatus:
        radioGroupFilter?.value === "all" ? undefined : radioGroupFilter?.value,
      adminId: Number(forwardingManagerSelectFilterValue),
      ...debouncedSearchTermWithObject,
      ...(dateSearchType === "promotionDetailDate" &&
        startDate &&
        endDate && {
          promotionDetailFromDate: startDate,
          promotionDetailToDate: endDate,
        }),
      ...(dateSearchType === "userDate" &&
        startDate &&
        endDate && {
          userFromDate: startDate,
          userToDate: endDate,
        }),
    },
    true
  );

  const { data: basicInfo } = ADMIN_PROMOTION_QUERY.useGetPromotionBasicInfo({
    adminId: forwardingManagerSelectFilterValue as number,
  });

  const {
    mutate: editPromotion,
    ResponseHandler: ResponseHandlerOfEditPromotion,
  } = ADMIN_PROMOTION_QUERY.useEditPromotion({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();

        if (showsPromotionDetailStatusConfirmModal) {
          setShowsPromotionDetailStatusConfirmModal(false);
        }

        queryClient.invalidateQueries(ADMIN_PROMOTION_QUERY_KEY_GEN.all());
      },
      customizeMessage: () => ({
        title: "상태를 변경했습니다.",
      }),
    },
  });

  const {
    mutate: updatePromotionManager,
    ResponseHandler: ResponseHandlerOfUpdatePromotionManager,
  } = ADMIN_PROMOTION_QUERY.useUpdatePromotionManager({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        queryClient.invalidateQueries(ADMIN_PROMOTION_QUERY_KEY_GEN.all());
      },
      customizeMessage: () => ({
        title: "담당자를 업데이트했습니다.",
      }),
    },
  });

  const handlePromotionManagerUpdateChange = useCallback(
    (changeValue: SelectChangeEvent<number>, promotionId: number) => {
      updatePromotionManager({
        managerId: changeValue.target.value as number,
        // TODO: pathParams를 사용하는 방식으로 수정
        _customPath: `/promotion/manager/${promotionId}`,
      });
    },
    [updatePromotionManager]
  );

  const handlePromotionStatusChange = useCallback(
    (selectStatus: AdminPromotionStatus, promotionDetailId: number) => {
      if (selectStatus === "pending" || selectStatus === "canceled") {
        setPromotionDetailId(promotionDetailId);
        setSelectedStatus(selectStatus);
        setShowsPromotionDetailStatusConfirmModal(true);
        return;
      }

      editPromotion({
        status: selectStatus,
        pathParams: {
          promotionDetailId,
        },
      });
      return;
    },
    [editPromotion]
  );

  const handlePromotionDetailStatusChange = (
    detailStatus: AdminPromotionDetailStatus
  ) => {
    editPromotion({
      status: selectedStatus,
      detailStatus,
      pathParams: {
        promotionDetailId,
      },
    });
  };

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "forwardingManager",
        disablePadding: false,
        label: "담당자",
      },
      {
        id: "status",
        disablePadding: false,
        label: "상태",
      },
      {
        id: "companyName",
        disablePadding: false,
        label: "회사명",
      },
      {
        id: "user",
        disablePadding: false,
        label: "담당자",
      },
      {
        id: "file",
        disablePadding: false,
        label: "견적파일",
      },
      {
        id: "bidId",
        disablePadding: false,
        label: "의뢰번호",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "신청일(가입일)",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!promotionList?.list) return [];

    return promotionList.list.map((v) => {
      const row: TableBodyRow<CellId> = {
        forwardingManager: v.user ? (
          v.user.forwardingManager.name
        ) : (
          <FormControl variant="standard">
            <Select
              value={v.manager?.id}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                handlePromotionManagerUpdateChange(e, v.id);
              }}
            >
              {adminData
                .filter((v) => {
                  return v.name === "정정훈" || v.name === "손석균";
                })
                .map((v) => (
                  <MenuItem key={v.id} value={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ),
        status: (
          <FormControl variant="standard">
            <Select
              value={v.status}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                handlePromotionStatusChange(
                  e.target.value as AdminPromotionStatus,
                  v.id
                );
              }}
            >
              {radioGroupOptionList
                .filter((v) => {
                  return v.value !== "all";
                })
                .map((v) => {
                  return (
                    <MenuItem key={v.value} value={v.value}>
                      {v.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        ),
        companyName: v.user ? v.user.company : v.companyName,
        user: (
          <Box>
            <Typography>{v.user ? v.user.name : v.userName}</Typography>

            <Typography>{v.phone}</Typography>

            <Typography>{v.email}</Typography>
          </Box>
        ),
        file: (
          <IconButton
            color={v.attachments.length > 0 ? "primary" : undefined}
            onClick={(e) => {
              e.stopPropagation();
              setListId(v.id);
              setShowsUploadModal(true);
            }}
          >
            <BackupIcon />
          </IconButton>
        ),
        bidId: (
          <Button
            variant={v.bidId ? "text" : undefined}
            disabled={v.status === "waiting"}
            onClick={(e) => {
              e.stopPropagation();
              setListId(v.id);
              setBidId(v.bidId);
              setIsPromotion(v.activateFlag);
              setShowsChangeBidInputModal(true);
            }}
          >
            {v.bidId ? v.bidId : "의뢰번호 등록"}
          </Button>
        ),
        createdAt: (
          <Box>
            <Typography component={"div"}>
              {toFormattedDate(v.createdAt, "YYYY-MM-DD")}
            </Typography>

            <Typography component={"div"}>
              {v.user ? toFormattedDate(v.user.createdAt, "YYYY-MM-DD") : ""}
            </Typography>
          </Box>
        ),
        handleRowClick: () => {
          history.push(`/promotion/${v.id}`);
        },
      };

      return row;
    });
  }, [
    adminData,
    handlePromotionManagerUpdateChange,
    handlePromotionStatusChange,
    history,
    promotionList?.list,
  ]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          px: 1,
          pt: 1.5,
          gap: 1,
          background: "#fff",
        }}
      >
        {ForwardingManagerSelectFilterPanel}

        <Box sx={{ display: "flex" }}>{PromotionTableFilter}</Box>
      </Box>

      <Box sx={{ px: 1, background: "#fff" }}>{RadioGroupFilterPanel}</Box>

      <Box sx={{ p: 1, background: "#fff" }}>
        <Table
          headCells={headCells}
          rows={rows}
          toolbarItems={{
            left: [
              <Typography key="total">
                총 {promotionList?.total || 0}건
              </Typography>,
            ],
          }}
          pagination={{
            totalCount: promotionList?.total || 0,
            perPage,
            setPerPage,
            currentPage,
            setCurrentPage,
          }}
        />
      </Box>

      {showsUploadModal && listId && (
        <PromotionFileUpload
          showsUploadModal={showsUploadModal}
          setShowsUploadModal={setShowsUploadModal}
          listId={listId}
        />
      )}

      {showsChangeBidInputModal && listId && (
        <ChangeBidInputModal
          setShowChangeBidInputModal={setShowsChangeBidInputModal}
          showChangeBidInputModal={showsChangeBidInputModal}
          listId={listId}
          bidId={bidId}
          isPromotion={isPromotion}
        />
      )}

      {showsPromotionDetailStatusConfirmModal && (
        <PromotionDetailStatusConfirmModal
          selectedStatus={selectedStatus}
          showsPromotionDetailStatusConfirmModal={
            showsPromotionDetailStatusConfirmModal
          }
          setShowsPromotionDetailStatusConfirmModal={
            setShowsPromotionDetailStatusConfirmModal
          }
          handlePromotionDetailStatusChange={handlePromotionDetailStatusChange}
        />
      )}

      {ResponseHandlerOfEditPromotion}

      {ResponseHandlerOfUpdatePromotionManager}
    </Box>
  );
};

export default PromotionTable;
