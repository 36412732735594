import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { IconButton, Tooltip } from "@mui/material";
import { useRecoilValue } from "recoil";

import { FilterDataKey } from "@sellernote/_shared/src/types/forwarding/common";
import { SHARED_FOR_FORWARDING_ADMIN_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/states/filters";


import useInProgressFilter from "./useInProgressFilter";
import useForwardingManagerIdFilter from "@sellernote/_shared-for-forwarding-admin/src/hooks/useForwardingManagerIdFilter";


export default function useTableHeadPanel(filterDataKey: FilterDataKey) {
  const { pathname } = useLocation();

  const filterData = useRecoilValue(
    SHARED_FOR_FORWARDING_ADMIN_ATOMS[filterDataKey]
  );

  const {
    ForwardingManagerFilter,
    headFilterData: ForwardingManagerIdFilterData,
    handleReset: ForwardingManagerIdReset,
  } = useForwardingManagerIdFilter(filterData.forwardingManagerIdList);

  const {
    InProgressFilter,
    headFilterData: InProgressFilterData,
    handleReset: InProgressReset,
  } = useInProgressFilter(filterData);

  const tableHeadFilterData = useMemo(() => {
    if (pathname.includes("/sub")) {
      return {
        ...ForwardingManagerIdFilterData,
      };
    }
    return {
      ...ForwardingManagerIdFilterData,

      ...InProgressFilterData,
    };
  }, [ForwardingManagerIdFilterData, InProgressFilterData, pathname]);

  const AllResetButton = useMemo(() => {
    return (
      <Tooltip title="테이블 헤드 필터 전체 리셋">
        <IconButton
          onClick={() => {
            ForwardingManagerIdReset();
            InProgressReset();
          }}
        >
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    );
  }, [ForwardingManagerIdReset, InProgressReset]);

  const TableHeadPanel = {
    ForwardingManagerFilter: ForwardingManagerFilter,
    InProgressFilter,
    AllResetButton: AllResetButton,
  };
  const NotYetTableHeadPanel = {
    ForwardingManagerFilter: ForwardingManagerFilter,
    AllResetButton: AllResetButton,
  };
  return {
    tableHeadFilterData,
    TableHeadPanel,
    NotYetTableHeadPanel,
    AllResetButton,
  };
}
