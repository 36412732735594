import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import {
  getAllTotalItemPriceOfFeeData,
  getAllTotalVatPriceOfFeeData,
  getTotalTaxExemptPriceOfFeeData,
} from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "@sellernote/_shared-for-forwarding-admin/src/constants";

import RequestButton from "./RequestButton";

const boxCommonStyle = {
  display: "flex",
  gap: 1,
};

/**
 * 최종금액과 요청 버튼이 있는 하단 고정 바
 */
function RequestButtonAndTotalPriceBottomStickyBar({
  invoiceState,
  shipmentDetailData,
  invoiceData,
  directAirRton,
  exchangeRateDate,
  sessionInvoiceId,
  onSessionInvoiceChange,
}: {
  invoiceState: ApplyBidFormData;
  shipmentDetailData: TrelloBidDetail;
  invoiceData: InvoiceData | undefined;
  directAirRton: number;
  /** 환율 기준날짜 */
  exchangeRateDate: string;
  sessionInvoiceId: string | null;
  onSessionInvoiceChange: (invoiceId: string) => void;
}) {
  /** 영세 과세 합계 */
  const totalPrice = (() =>
    getAllTotalItemPriceOfFeeData(
      invoiceState.freightFeeData,
      invoiceState.domesticFeeData,
      invoiceState.localFeeData,
      invoiceState.inlandFeeData,
      invoiceState.otherFeeData,
      invoiceState.taxFeeData
    ))();

  /** 부가세 */
  const vatPrice = (() =>
    getAllTotalVatPriceOfFeeData(
      invoiceState.freightFeeData,
      invoiceState.domesticFeeData,
      invoiceState.localFeeData,
      invoiceState.inlandFeeData,
      invoiceState.otherFeeData,
      invoiceState.taxFeeData
    ))();

  /**
   * 관부가세 합계 필드의 금액
   * 수입 세금 섹션 항목의 합을 사용함
   */
  const totalTaxFee = (() => {
    const { taxFeeData } = invoiceState;

    if (!taxFeeData || !taxFeeData.length) return 0;

    const total = taxFeeData.reduce((acc, { itemPrice }) => acc + itemPrice, 0);

    return total;
  })();

  /** 비과세 합계 */
  const taxExemptPrice = (() => {
    const allFeeData = invoiceState.freightFeeData.concat(
      invoiceState.domesticFeeData,
      invoiceState.localFeeData,
      invoiceState.inlandFeeData,
      invoiceState.otherFeeData,
      invoiceState.taxFeeData
    );

    return getTotalTaxExemptPriceOfFeeData(allFeeData);
  })();

  return (
    <Box
      sx={{
        width: "100%",
        position: "sticky",
        bottom: "0px",
        display: "flex",
        padding: 1,
        // 항목 체크박스의 기본 z-index가 1이라 고정바 버튼 클릭시 문제가 생겨 결정
        zIndex:
          FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tradingStatementBottomSticky,
        justifyContent: "space-between",
        backgroundColor: grey[800],
      }}
    >
      <Box
        sx={{ display: "flex", gap: 1, alignItems: "center", color: grey[400] }}
      >
        <Box
          sx={{
            ...boxCommonStyle,
          }}
        >
          <Typography variant="body1">영세 과세 합계:</Typography>

          <Typography variant="body1">
            {toThousandUnitFormat(totalPrice)}
          </Typography>
        </Box>

        <Typography variant="body1">+</Typography>

        <Box
          sx={{
            ...boxCommonStyle,
          }}
        >
          <Typography variant="body1">부가세:</Typography>

          <Typography variant="body1">
            {toThousandUnitFormat(vatPrice)}
          </Typography>
        </Box>

        <Typography variant="body1">+</Typography>

        <Box
          sx={{
            ...boxCommonStyle,
          }}
        >
          <Typography variant="body1">관부가세 합계:</Typography>

          <Typography variant="body1">
            {toThousandUnitFormat(totalTaxFee)}
          </Typography>
        </Box>

        <Typography variant="body1">+</Typography>

        <Box
          sx={{
            ...boxCommonStyle,
          }}
        >
          <Typography variant="body1">비과세:</Typography>
          <Typography variant="body1">
            {toThousandUnitFormat(taxExemptPrice)}
          </Typography>
        </Box>

        <Typography variant="body1">=</Typography>

        <Box
          sx={{
            ...boxCommonStyle,
            alignItems: "center",
          }}
        >
          <Typography variant="body1" color={"#ffffff"}>
            최종금액:
          </Typography>

          <Typography variant="h6" color={"#ffffff"}>
            {toThousandUnitFormat(totalPrice + vatPrice + taxExemptPrice)}
          </Typography>
        </Box>
      </Box>

      <RequestButton
        shipmentDetailData={shipmentDetailData}
        invoiceState={invoiceState}
        invoiceData={invoiceData}
        directAirRton={directAirRton}
        totalPrice={totalPrice}
        vatPrice={vatPrice}
        taxExemptPrice={taxExemptPrice}
        exchangeRateDate={exchangeRateDate}
        sessionInvoiceId={sessionInvoiceId}
        onSessionInvoiceChange={onSessionInvoiceChange}
      />
    </Box>
  );
}

export default RequestButtonAndTotalPriceBottomStickyBar;
