import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/BUSINESS_MANAGEMENT_QUERY";
import {
  FreightType,
  PagingResponseForBoard,
} from "@sellernote/_shared/src/types/common/common";
import { BusinessManagementListItem } from "@sellernote/_shared/src/types/forwarding/businessManagement";
import {
  changeBusinessManagementServiceTypeToNameKr,
  changeBusinessManagementStatusToNameKr,
} from "@sellernote/_shared/src/utils/forwarding/businessManagement";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/Table";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import CommentModal from "./CommentModal";

type CellId = keyof BusinessManagementListItem | "applyBid";

const BeforeEstimateTable = ({
  perPage,
  setPerPage,
  setPage,
  page,
  businessManagementList,
}: {
  perPage: number;
  setPerPage: (value: number) => void;
  setPage: (value: number) => void;
  page: number;
  businessManagementList:
    | PagingResponseForBoard<BusinessManagementListItem>
    | undefined;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const [showsCommentModal, setShowsCommentModal] = useState(false);
  const [bidDetailId, setBidDetailId] = useState(0);

  const { mutate: changeBidStatusToEstimate } =
    BUSINESS_MANAGEMENT_QUERY.useChangeBidStatusToEstimate();

  const handleBidStatusChange = useCallback(
    (bidId: number, freightType: FreightType) => {
      changeBidStatusToEstimate(
        {
          status: "estimating",
          pathParams: {
            bidId,
          },
        },
        {
          onSuccess: () => {
            history.push(`/bid/applyBid/${freightType}/${bidId}`);
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    },
    [changeBidStatusToEstimate, handleSnackbarOpen, history]
  );

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "managerName",
        disablePadding: false,
        label: "담당자",
      },
      {
        id: "bidId",
        disablePadding: false,
        label: "의뢰번호",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "견적생성일",
      },
      {
        id: "status",
        disablePadding: false,
        label: "현재 상태",
      },
      {
        id: "companyName",
        disablePadding: false,
        label: "팀명(회사명)",
      },
      {
        id: "serviceType",
        disablePadding: false,
        label: "운송유형",
      },
      {
        id: "route",
        disablePadding: false,
        label: "구간",
      },

      {
        id: "comments",
        disablePadding: false,
        label: "비고",
      },

      {
        id: "applyBid",
        disablePadding: false,
        label: "견적제출",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!businessManagementList?.list) return [];

    return businessManagementList?.list.map((v) => {
      const row: TableBodyRow<CellId> = {
        managerName: v.managerName,
        bidId: (
          <Button
            onClick={() => {
              history.push(`/bid/detail/${v.bidId}`);
            }}
          >
            {v.bidId}
          </Button>
        ),
        createdAt: v.createdAt,
        status: changeBusinessManagementStatusToNameKr(v),
        companyName: `${v.teamName ?? ""}${
          v.companyName ? `(${v.companyName})` : ""
        }`,
        serviceType: changeBusinessManagementServiceTypeToNameKr(v),
        route: v.route,
        comments: (
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setBidDetailId(v.bidId);
              setShowsCommentModal(true);
            }}
          >
            {"확인"}
          </Button>
        ),
        applyBid: (
          <Button
            variant="contained"
            onClick={async () => {
              if (v.status === "committed") {
                handleBidStatusChange(v.bidId, v.freightType);
              } else {
                history.push(`/bid/applyBid/${v.freightType}/${v.bidId}`);
              }
            }}
          >
            견적 제출
          </Button>
        ),
      };

      return row;
    });
  }, [businessManagementList?.list, handleBidStatusChange, history]);

  return (
    <>
      <Table
        toolbarItems={{
          left: [
            <Typography key="total">
              총 {businessManagementList?.total || 0}건
            </Typography>,
          ],
        }}
        headCells={headCells}
        rows={rows}
        pagination={{
          totalCount: businessManagementList?.total || 0,
          perPage,
          setPerPage,
          currentPage: page,
          setCurrentPage: setPage,
        }}
      />

      {showsCommentModal && (
        <CommentModal
          setShowsCommentModal={setShowsCommentModal}
          showsCommentModal={showsCommentModal}
          bidDetailId={bidDetailId}
        />
      )}
    </>
  );
};

export default BeforeEstimateTable;
