import { GET_ADMIN_NEW_BID_USER_REQ_SEARCH_KIND } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidUser";
import { TermSearchType } from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";

const TERM_SEARCH_TYPE_OPTION_LIST: TermSearchType<GET_ADMIN_NEW_BID_USER_REQ_SEARCH_KIND>[] =
  [
    {
      label: "회사명",
      value: "company",
    },
    {
      label: "담당자 이름",
      value: "name",
    },
    {
      label: "연락처",
      value: "phone",
    },
    {
      label: "이메일",
      value: "email",
    },
  ];

export { TERM_SEARCH_TYPE_OPTION_LIST };
