import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";

import PromotionTable from "./PromotionTable";

const Promotion = () => {
  const { adminUserList, adminUserId } = ADMIN_USER_QUERY.useGetAdminUserList();

  if (!adminUserList) {
    return (
      <Layout>
        <Loading active={true}></Loading>
      </Layout>
    );
  }

  return (
    <Layout title="프로모션 관리">
      <PromotionTable adminData={adminUserList} adminUserId={adminUserId} />
    </Layout>
  );
};

export default Promotion;
