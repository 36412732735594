import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import Loading from "components/Loading";

import BusinessManagementInfo from "./BusinessManagementInfo";

const Bid = () => {
  const { adminUserList, adminUserId } = ADMIN_USER_QUERY.useGetAdminUserList();

  if (!adminUserList) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout title="영업 현황">
      <BusinessManagementInfo
        adminData={adminUserList}
        adminUserId={adminUserId}
      />
    </Layout>
  );
};

export default withRequireAuth(Bid);
